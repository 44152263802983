
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FieldWrapper',
  props: {
    label: String,
    name: String,
    hint: String,
    errors: Array
  }
})
